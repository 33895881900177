import React from "react";
import Phone from "@mui/icons-material/Phone";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Email from "@mui/icons-material/Email";

const Social = () => {
  return (
    <div className="socialMedia">
      <span className="icon-link">
        <a href="tel:+381613106106">
          <Phone titleAccess="Phone" />
        </a>
      </span>
      <span className="icon-link">
        <a href="https://linkedin.com/in/jelena-ilic-a3ba0418a/" target="blank">
          <LinkedIn titleAccess="LinkedIn" />
        </a>
      </span>
      <span className="icon-link">
        <a href="mailto:lazarevicilicjelena@gmail.com">
          <Email titleAccess="e-mail" />
        </a>
      </span>
    </div>
  );
};

export default Social;
