import React from "react";

const Terms = () => {
  return (
    <div className="container">
      <h2>Terms and conditions...</h2>
    </div>
  );
};

export default Terms;
