import React from "react";
import RouteContext from "../../Context";
import { useContext } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from "../images/main.jpg";
import img2 from "../images/main1.jpg";
import img3 from "../images/main2.jpg";
import img4 from "../images/main3.jpg";
import img5 from "../images/main4.jpg";
import img6 from "../images/main5.jpg";
import img7 from "../images/main6.jpg";
import img8 from "../images/main7.jpg";

const Start = () => {
  const { page, setPage } = useContext(RouteContext);
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <img
      src={img1}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      role="presentation"
      style={{ borderRadius: "15px" }}
    />,
    <img
      src={img2}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      style={{ borderRadius: "15px" }}
    />,
    <img
      src={img3}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      style={{ borderRadius: "15px" }}
    />,
    <img
      src={img4}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      style={{ borderRadius: "15px" }}
    />,
    <img
      src={img5}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      style={{ borderRadius: "15px" }}
    />,
    <img
      src={img6}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      style={{ borderRadius: "15px" }}
    />,
    <img
      src={img7}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      style={{ borderRadius: "15px" }}
    />,
    <img
      src={img8}
      className="sliderimg"
      onDragStart={handleDragStart}
      alt=""
      style={{ borderRadius: "15px" }}
    />,
  ];
  return (
    <div className="container">
      <h2 style={{ margin: "2rem 1rem" }}>DOBRODOŠLI</h2>
      <div className="start">
        <div id="slider">
          <AliceCarousel
            mouseTracking
            items={items}
            autoPlay
            infinite
            disableDotsControls
            //           animationType="fadeout"
            animationDuration={3000}
            disableButtonsControls
            autoHeight
            autoWidth
          />
        </div>
      </div>
      {/* <ul style={{ lineHeight: "3rem", cursor: "pointer" }}>
        <li
          onClick={() => {
            setPage(1);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          style={{ color: page === 0 ? "#5f4444" : "#3d2608" }}
        >
          O Nama
        </li>
        <li
          onClick={() => {
            setPage(7);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          style={{ color: page === 3 ? "#5f4444" : "#3d2608" }}
        >
          Prodavnica
        </li>
        <li
          onClick={() => {
            setPage(4);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          style={{ color: page === 4 ? "#5f4444" : "#3d2608" }}
        >
          Tim
        </li>
        <li
          onClick={() => {
            setPage(5);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          style={{ color: page === 5 ? "#5f4444" : "#3d2608" }}
        >
          Blog
        </li>
        <li
          onClick={() => {
            setPage(3);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          style={{ color: page === 5 ? "#5f4444" : "#3d2608" }}
        >
          Galerija
        </li>
      </ul> */}
    </div>
  );
};

export default Start;
