import React from "react";
import { useContext } from "react";
import RouteContext from "../../Context";

const Home = () => {
  const { setPage } = useContext(RouteContext);
  return (
    <div className="container">
      <div className="home">
        <h4>
          <strong>NAŠA PRIČA</strong>
        </h4>
        <p>
          Priča o nama je zapravo priča o njoj koja pravi savršene
          minerale,neverovatnu raznolikost zelene boje, koja izražava obnovu i
          život, koje samo ona, priroda može da napravi. To je priča o hobiju,
          ljubavi i ljudima. Prave ljubavi se jednostavno rode, bez nekih
          posebnih razloga. Pronašli smo našu srećnu priču bez kraja. Ona je i
          vaša i naša. Svi smo{" "}
          <span onClick={() => setPage(4)}>porodični ljudi</span> koje je
          povezala "zelena putanja". Tempo savremenog života u urbanim sredinama
          navodi nas na neredovnu ishranu bogatu aditivima, ugljenim hidratima i
          veštačkim šećerima. Svi mi koji živimo u velikim gradovim nepovratno
          smo izloženi svakodnevnim stresom i neredovnoj ishrani Kako smo? Da li
          smo toga negde svesni?
        </p>
      </div>
      <div className="home">
        <h4>LJUBAV SE RAĐA NEŽNO</h4>
        <p>
          Priroda nam je dala apsolutno sve što nam je za život potrebno u njoj
          je i hrana i lek. Pčelarstvo je za nas više od hobija - to je način da
          se vratimo prirodi i podržimo očuvanje pčela, ključnih polinizatora
          našeg ekosistema. Kroz pčelarstvo, učimo o delikatnom balansu između
          čoveka i prirode. Živimo u urbanim sredinama, ali smo odlučili da
          svojim primerima pokažemo kako zdrava ishrana, šetnje kroz prirodu i
          svakodnevna upotreba meda mogu obogatiti naše živote i stvoriti zdrav
          i stabilan balans u današnjem brzom svetu. Znamo da naša zelena
          putanja nije lak put, ali želimo da budemo inspiracija svima koji
          misle i žive zdravo. Nama je inspiracija bila jedina hrana na planeti
          koja nema rok trajanja. Koju proizvodi kraljica prirode i njena
          porodica. Ono što rade za prirodi i nas, nauka nije u potpunosti
          dokučila.
        </p>
      </div>
      <div className="home">
        <h4>SLATKO REŠENJE</h4>
        <p>
          Pčelarenje nije samo pčela i med, to je sve ono što ti u pogled stane!
          U skladu sa time, napravili smo sopstvenu mrežu saradnika i
          dobavljača-od izvora do gotovih proizvoda. Pored meda i proizvoda od
          meda, osetićete mirise šuma i livada, kroz bilje i čajeve koje ćemo
          vam predstaviti. Na našem sajtu ćete pronaći savete o pčelarstvu,
          recepte, inspiraciju za šetnje kroz prirodu, kako, gde i šta da
          vežbate, mogućnost opuštanja u mestima zvuka i mirisa prirode i
          tišine, jednom rečju, sve što će vam pomoći da postignete balans i
          harmoniju u vašem životu.
        </p>
      </div>
    </div>
  );
};

export default Home;
