import React, { useState } from "react";
import teamMembers from "../../data/team.json";
import Member from "./Member";

const Team = () => {
  const [member, setMember] = useState(null);
  return (
    <>
      {member ? (
        <Member
          teamMember={member?.membername}
          logo={member.logo}
          teamBio={
            member?.link ? (
              <>
                {member.cv}
                <a href={member.link} target="blank">
                  {member.link}
                </a>
                {member.cv2}
              </>
            ) : (
              member.cv
            )
          }
          recept={member?.recept}
          setMember={setMember}
        />
      ) : (
        <div className="container">
          <h2 style={{ marginTop: "10px" }}>Tim Naša Priča</h2>
          <div className="member">
            <h4>Jelena Ilić</h4>
            <img
              src="Jelena.jpg"
              alt="Jelena"
              onClick={() => setMember(teamMembers.jelena)}
            ></img>
          </div>
          <div className="member">
            <h4>Ivana Golubović</h4>
            <img
              src="Ivana.jpg"
              alt="Ivana"
              onClick={() => setMember(teamMembers.ivana)}
            ></img>
          </div>
          <div className="member">
            <h4>Ana Vučetić</h4>
            <img
              src="ana.jpg"
              alt="Ana"
              onClick={() => setMember(teamMembers.ana)}
            ></img>
          </div>
          <div className="member">
            <h4>
              <a
                href="https://www.facebook.com/johngunnar.halleland"
                target="blank"
              >
                John Gunnar Halleland
              </a>
            </h4>
            <img
              src="jon.jpg"
              alt="Jon"
              onClick={() => setMember(teamMembers.jon)}
            ></img>
          </div>
          <div className="member">
            <h4>Marija Spasić</h4>
            <img
              src="marija.jpg"
              alt="Marija"
              onClick={() => setMember(teamMembers.marija)}
            ></img>
          </div>
        </div>
      )}
    </>
  );
};

export default Team;
