import React from "react";

const Terms = () => {
  return (
    <div className="container">
      <div className="home">
        <h4>MED I PROIZVODI OD MEDA</h4>
        <h6>Bagremov</h6>
        <h6>Livadski</h6>
        <h6>Suncokretov</h6>
        <h6>Šumski</h6>
        {/* <h6>Saće u medu</h6>
        <h6>Propolis</h6>
        <h6>Sveće od prirodnog voska</h6>
        <br></br>
        <h4>SLATKI POKLONI:</h4>
        <h6>Crna Bela i Žuta Med Čokolada</h6>
        <h6>Zdrava Žvaka</h6>
        <h6>Poruka u medu</h6>
        <h4>BILJE </h4>
        <h4>ZVUCI PRIRODE</h4>
        <h6>Odmor na naš način</h6> */}
        <br></br>
        <p>
          Bagremov med je jedan od najpopularnijih vrsta meda u Srbiji, poznat
          je po svojim specifičnim svojstvima i brojnim zdravstvenim koristima.
          Najčešća preporuka deci starijoj od godinu dana. Bagremov med je
          monoflorni med, koji potiče od nektara cveta bagrema. On je blagog
          ukusa i prijatnih, neutralnih aroma. Bogat je vitaminom C i svojim
          sastavom deluje na opuštanje organizma. Sadrži manju količinu polena,
          te je pogodniji za korišćenje kod osoba alergičnih na određene vrste
          polena. Svojim energetskim sastavom podiže opštu snagu organizma, budi
          vedro raspoloženje i brzo oporavlja telo posle fizičkih i umnih
          napora. Bitan je za sportiste, koriste ga trudnice, a u narodnoj
          medicini koristi se kod stomačno - crevnih obolenja, obolenja jetre i
          respiratornih infekcija. Kao i svaki med i bagremov med poseduje blage
          antibakterijske i protivzapaljene osobine. Odličan je antiseptik,
          stoga se može koristiti u zaceljivanju sitnih povreda i posekotina. Uz
          umerenu fizičku aktivnost, konzumiranje bagremovog meda topi
          kilograme. Bagremov med se u ishrani beba može uvesti od navršenih
          godinu dana života. Davanje meda pre tog vremena nije preporučljivo
          jer organizam deteta nije dovoljno razvijen. SNAŽAN ANTIOKSIDANS:
          Bagremov med sadrži antioksidante koji pomažu u zaštiti tela od
          oštećenja uzrokovanih slobodnim radikalima. Antioksidansi podržavaju
          zdravlje ćelija i smanjuju rizik od hroničnih bolesti. PRIRODNI
          ANTIBAKTERIJSKI AGENS: Bagremov med ima prirodna antibakterijska
          svojstva i može pomoći u borbi protiv bakterijskih infekcija. Njegova
          primena na povrede ili opekotine može ubrzati proces zarastanja.
          SMANJENJE KAŠLJA I PROMUKLOSTI: Bagremov med se često koristi kao
          prirodni lek za smirivanje kašlja i promuklosti. Može umiriti
          iritirano grlo i olakšati disanje. POMOĆ U KONTROLI ALERGIJA:
          Konzumiranje lokalnog bagremovog meda može pomoći u smanjenju simptoma
          sezonskih alergija. Prema teoriji "polenske terapije", mala količina
          lokalnog polena u medu može pomoći telu da razvije toleranciju na
          alergene. DOBAR IZVOR ENERGIJE: Bagremov med je odličan izvor prirodne
          energije zbog visokog sadržaja prirodnih šećera (fruktoze i glukoze).
          To ga čini idealnim izborom za brzu energiju i prevenciju umora.
          POMOĆNIK U VARENJU: Med može poboljšati probavu i pomoći u rešavanju
          problema sa varenjem. Blagi laksativni efekat meda može pomoći kod
          zatvora. NEGUJE NAM KOŽU: Bagremov med se često koristi u kozmetičkim
          proizvodima zbog svojih hidratantnih i umirujućih svojstava. Može
          pomoći u održavanju zdrave i sjajne kože. POMAŽE U SMANJENJU STRESA:
          Konzumacija meda može pomoći u smanjenju stresa i poboljšanju
          mentalnog blagostanja. Prirodni šećeri u medu mogu podstaći lučenje
          hormona sreće, kao što je serotonin.{" "}
        </p>
        <p>
          IMUNO PODRŠKA: Redovno konzumiranje bagremovog meda može ojačati
          imunološki sistem i pomoći telu da se bori protiv infekcija. DRUGAR
          NAŠEG SRCA: Med može pomoći u održavanju zdravlja srca smanjenjem
          nivoa holesterola i krvnog pritiska.
        </p>
        <p>
          NAŠ PČELAR NA VAŠIM VRATIMA. VAŠE JE SAMO DA NARUČITE, sve ostalo je
          naše zadovoljstvo! Bagremov med 1kg-1200 Saće u bagremovom medu 1
          kg-1500 Bagremov med ½ kg - 600 din.
        </p>
      </div>
      {/* <h2
        style={{ color: "goldenrod", cursor: "pointer" }}
        onClick={() => setPage(3)}
      >
        Galerija
      </h2> */}
    </div>
  );
};

export default Terms;
