import React from "react";
import RouteContext from "../Context";
import { Reorder } from "@mui/icons-material";
import { useContext, useState, useEffect } from "react";

const Header = () => {
  const { page, setPage, small, setSmall } = useContext(RouteContext);
  const [expand, setExpand] = useState(false);
  const [sub, setSub] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 480) {
      setExpand(true);
    } else {
      setSmall(true);
      setExpand(false);
    }
  }, []);

  return (
    <div className="header">
      <div className={expand && small ? "close" : "header-logo"}>
        <img
          src="bee.jpg"
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSub(false);
            setPage(0);
          }}
        ></img>
      </div>
      <div className="menu">
        <ul className={expand ? "open" : "close"}>
          <li
            onClick={() => {
              setPage(1);
              setSub(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            style={{ color: page === 0 ? "#5f4444" : "#3d2608" }}
          >
            O Nama
          </li>
          <li
            onClick={() => setSub(!sub)}
            onMouseEnter={() => setSub(true)}
            style={{ color: page === 3 ? "#5f4444" : "#3d2608" }}
          >
            Prodavnica
          </li>
          <li
            onClick={() => {
              setPage(4);
              setSub(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            style={{ color: page === 4 ? "#5f4444" : "#3d2608" }}
          >
            Tim
          </li>
          <li
            onClick={() => {
              setPage(5);
              setSub(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            style={{ color: page === 5 ? "#5f4444" : "#3d2608" }}
          >
            Blog
          </li>
        </ul>
      </div>
      <div
        className={sub ? "submenu" : "submenu-hide"}
        onMouseLeave={() => setSub(false)}
      >
        <ul>
          <li onClick={() => setPage(7)}>MED</li>
          <li onClick={() => setPage(3)}>SVEĆE</li>
          <li onClick={() => setPage(8)}>POKLONI</li>
        </ul>
      </div>
      <button
        onClick={() => {
          setExpand((prev) => !prev);
          setPage(0);
        }}
      >
        <Reorder />
      </button>
    </div>
  );
};

export default Header;
