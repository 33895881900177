import React from "react";

const Gifts = () => {
  return (
    <div className="container">
      <h2>Poklončići</h2>
      <div className="three-column">
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img16.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img17.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img18.jpg" alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default Gifts;
