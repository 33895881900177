import React from "react";

const Member = ({ teamMember, teamBio, logo, recept, setMember }) => {
  return (
    <div className="container">
      <h2>{teamMember}</h2>
      <img
        src={logo}
        alt="teamMember"
        style={{ width: "200px", height: "200px" }}
        onClick={() => setMember(null)}
      ></img>
      <div>
        <div className="home">
          <p style={{ marginTop: "15px" }}>{teamBio}</p>
          {recept
            ? recept.map((r, i) => <p style={{ marginTop: "15px" }}>{r}</p>)
            : null}
        </div>
      </div>
    </div>
  );
};

export default Member;
