import React from "react";

const Store = () => {
  return (
    <div className="container">
      <h2>Sveće od voska</h2>
      <div className="three-column">
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img1.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img2.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img3.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img4.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img5.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img6.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img7.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img8.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img9.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img10.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img11.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img13.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img14.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img15.jpg" alt=""></img>
        </div>
        <div
          style={{
            // width: "220px",
            // height: "220px",
            borderRadius: "0",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <img src="./img12.jpg" alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default Store;
