import React from "react";
import blog from "../blog.json";

const Blog = () => {
  return (
    <div className="container">
      <h2>Blog</h2>
      <br />
      <h4>{blog.title[0]}</h4>
      <p>{blog.text[0]}</p>
      <br />
      <br />
      <h4>{blog.title[1]}</h4>
      <p>{blog.text[1]}</p>
      <br />
      <br />
      <h4>{blog.title[2]}</h4>
      <p>{blog.text[2]}</p>
      <br />
      <br />
      <h4>{blog.title[3]}</h4>
      <p>{blog.text[3]}</p> <br />
      <br />
      <br />
      <h4>{blog.title[4]}</h4>
      <h4>Zašto su ovo must-have proizvodi:</h4>
      <p>
        {blog.text[4]} {blog.text[5]}
      </p>
      <br />
      <h4>MEŠAVINA ZA LEČENJE ANEMIJE I JAČANJE IMUNITETA:</h4>
      <h4>SASTOJCI</h4>
      <pre>
        {blog.sastojci.map((s, i) => {
          return <p>- {s}</p>;
        })}
      </pre>
      <br />
      <h4>NAČIN PRIPREME:</h4>
      <p>{blog.priprema}</p>
      <br />
      <h4>Doziranje:</h4>
      <p>{blog.doziranje}</p>
    </div>
  );
};

export default Blog;
